export enum ElementRole {
  Image = 'imageX1',
  ImageContainer = 'imageContainer',
  PlanName = 'planName',
  BadgeWidget = 'ribbon1',
  BadgeContainer = 'ribbonContainer',
  BadgeText = 'ribbonText',
  PricingWidget = 'currencyPosition1',
  Price = 'price',
  Frequency = 'frequency',
  Currency = 'currency',
  Description = 'planDescription',
  PlanDuration = 'planDuration',
  Button = 'ctaButton',
  ContentDividerHorizontal = 'horizontalContentDivider',
  ContentDividerVertical = 'contentDivider',
  ContentDividerContainer = 'contentDividerContainer',
  PerksWidget = 'benefits1',
  PerksContainer = 'perksContainer',
  PerkText = 'perkText',
  PerkIcon = 'perkIcon',
  PerkDivider = 'perkDivider',
  MultiStateBox = 'multiStateBox1',
  PlanWidget = 'singlePlan1',
  PlanContainer = 'singlePlanContainer',
}
