import { createBlocksModel } from '@wix/yoshi-flow-editor/blocks';

export default createBlocksModel({
  widgetName: 'Plan',
  props: {},
  methods: {
    setPlan: {
      params: [
        {
          name: 'plan',
          description: 'PublicPlan object',
        },
      ],
    },
  },
  events: {},
});
