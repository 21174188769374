import { createBlocksModel } from '@wix/yoshi-flow-editor/blocks';

export default createBlocksModel({
  widgetName: 'Pricing',
  props: {},
  methods: {
    setPricing: {
      params: [
        {
          name: 'price',
        },
        {
          name: 'currency',
        },
        {
          name: 'paymentFrequency',
        },
      ],
    },
  },
  events: {},
});
