import { ComponentRef, EditorSDK, WidgetDesignPresets } from '@wix/platform-editor-sdk';
import { EditorScriptFlowAPI, ConnectedComponentsBuilder, WidgetBuilder } from '@wix/yoshi-flow-editor';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';
import { SINGLE_PLAN_HELP_ARTICLE_ID } from '../../constants';
import { ElementRole } from '../../constants/elements';
import { PRESETS_BY_STATE, WidgetState } from '../../layout-config';
import { openShowHidePanel } from '../../showHidePanel/showHideActions';
import { SinglePlanInteractions } from '../../types/SinglePlanFedops';
import { openPlanForm } from '../../utils/widget';

export const getWidgetManifest: GetWidgetManifestFn = (builder, editorSDK, flowAPI) => {
  const t = flowAPI.translations.t;
  builder.configureWidgetDesign((designBuilder) => {
    designBuilder.set({
      title: t('blocks.design.title'),
      presetsTitle: t('blocks.design.presets-title'),
      customHelpId: SINGLE_PLAN_HELP_ARTICLE_ID,
    });
  });
  builder
    .gfpp()
    .set('layout', {
      onClick: (e) => {
        openLayoutPanel({ componentRef: e.detail.componentRef, editorSDK, flowAPI });
      },
    })
    .set('add', {
      onClick: (e) => {
        openShowHidePanel(editorSDK, e.detail.componentRef, flowAPI);
      },
    });

  Object.values(WidgetState).forEach((state) => {
    builder.configureState(state, (stateBuilder) => {
      stateBuilder.configureWidgetDesign((designBuilder) => {
        const presets = getPresets(state);
        designBuilder.setPresets(presets);
      });
    });
  });

  builder.configureConnectedComponents(ElementRole.ImageContainer, (imageContainerBuilder) =>
    configureImage({ builder: imageContainerBuilder, editorSDK, flowAPI }),
  );

  builder.configureConnectedComponents(ElementRole.BadgeWidget, (badgeBuilder) =>
    configureBadge({ builder: badgeBuilder, editorSDK, flowAPI }),
  );

  builder.configureConnectedComponents(ElementRole.PlanName, (nameBuilder) =>
    configureName({ builder: nameBuilder, editorSDK, flowAPI }),
  );

  builder.configureConnectedComponents(ElementRole.PricingWidget, (pricingBuilder) =>
    configurePricing({ builder: pricingBuilder, editorSDK, flowAPI }),
  );

  builder.configureConnectedComponents(ElementRole.Description, (descriptionBuilder) =>
    configureDescription({ builder: descriptionBuilder, editorSDK, flowAPI }),
  );

  builder.configureConnectedComponents(ElementRole.PlanDuration, (durationBuilder) =>
    configureDuration({ builder: durationBuilder, editorSDK, flowAPI }),
  );

  builder.configureConnectedComponents(ElementRole.Button, (buttonBuilder) =>
    configureButton({ builder: buttonBuilder, editorSDK, flowAPI }),
  );

  builder.configureConnectedComponents(ElementRole.PerksWidget, (perksBuilder) =>
    configureBenefits({ builder: perksBuilder, editorSDK, flowAPI }),
  );

  configureDivider({ builder, editorSDK, flowAPI });
};

function configureImage(params: {
  builder: ConnectedComponentsBuilder;
  flowAPI: EditorScriptFlowAPI;
  editorSDK: EditorSDK;
}) {
  const { builder, flowAPI, editorSDK } = params;
  const t = flowAPI.translations.t;
  builder.set({ displayName: t('blocks.label.image') });
  builder.behavior().set({ closed: { selectable: true, hideFromHierarchy: false }, preventHide: true });
  builder
    .gfpp()
    .set('mainAction1', {
      onClick: (e) => openPlanForm({ editorSDK, flowAPI, componentRef: e.detail.componentRef }),
      label: t('blocks.gfpp.change-image'),
    })
    .set('settings', { behavior: 'HIDE' })
    .set('link', { behavior: 'HIDE' })
    .set('connect', { behavior: 'HIDE' })
    .set('help', { id: SINGLE_PLAN_HELP_ARTICLE_ID });

  builder.configureWidgetDesign((imageDesignBuilder) => {
    imageDesignBuilder.set({
      title: t('blocks.image-design.title'),
      customHelpId: SINGLE_PLAN_HELP_ARTICLE_ID,
    });
    imageDesignBuilder.tabs().addTab((tabBuilder) => {
      tabBuilder.set({ label: t('blocks.image-design.tab-label') });
      tabBuilder.groups().set({ roles: [ElementRole.Image, ElementRole.ImageContainer] });

      tabBuilder
        .addSection((sectionBuilder) => {
          sectionBuilder.set({
            state: 'regular',
            category: 'fill',
            label: '',
            priority: 1,
            roles: [ElementRole.Image],
            styleParam: 'mediaOpacity',
          });
        })
        .addSection((sectionBuilder) => {
          sectionBuilder.set({
            state: 'regular',
            category: 'corners',
            label: '',
            priority: 1,
            roles: [ElementRole.Image, ElementRole.ImageContainer],
          });
        });
    });
  });
}

function configureBadge(params: {
  builder: ConnectedComponentsBuilder;
  flowAPI: EditorScriptFlowAPI;
  editorSDK: EditorSDK;
}) {
  const { builder, flowAPI, editorSDK } = params;
  const t = flowAPI.translations.t;
  builder.set({ displayName: t('blocks.label.ribbon') });
  builder
    .gfpp()
    .set('connect', { behavior: 'HIDE' })
    .set('mainAction1', {
      label: t('blocks.ribbon.edit-text'),
      onClick: (e) => {
        openChangeRibbonTextPanel({ editorSDK, componentRef: e.detail.componentRef, flowAPI });
      },
    })
    .set('help', { id: SINGLE_PLAN_HELP_ARTICLE_ID })
    .set('design', { behavior: 'DEFAULT' });

  builder.gfpp('mobile').set('mainAction1', { behavior: 'HIDE' });

  builder.configureWidgetDesign((badgeDesignBuilder) => {
    badgeDesignBuilder.set({ title: t('blocks.ribbon-design.title'), customHelpId: SINGLE_PLAN_HELP_ARTICLE_ID });
    badgeDesignBuilder
      .tabs()
      .addTab((tabBuilder) => {
        tabBuilder.set({ label: t('blocks.ribbon-design.text') });
        tabBuilder.groups().set({
          roles: [ElementRole.BadgeText],
        });
      })
      .addTab((tabBuilder) => {
        tabBuilder.set({ label: t('blocks.ribbon-design.background') });
        tabBuilder.groups().set({
          roles: [ElementRole.BadgeContainer],
        });
      });
  });
}

function configureName(params: {
  builder: ConnectedComponentsBuilder;
  flowAPI: EditorScriptFlowAPI;
  editorSDK: EditorSDK;
}) {
  const { builder, flowAPI, editorSDK } = params;
  const t = flowAPI.translations.t;
  builder.set({ displayName: t('blocks.label.plan-name') });
  builder.behavior().set({ dataEditOptions: 'TEXT_STYLE_ONLY' });
  builder
    .gfpp()
    .set('mainAction1', {
      label: t('blocks.plan-name.gfpp.design-text'),
      onClick: (e) => {
        editorSDK.editor.openNativeComponentPanel('', 'settings', {
          componentRef: e.detail.componentRef,
          helpId: SINGLE_PLAN_HELP_ARTICLE_ID,
        });
      },
    })
    .set('mainAction2', {
      onClick: (e) => openPlanForm({ editorSDK, flowAPI, componentRef: e.detail.componentRef }),
      label: t('blocks.plan-name.gfpp.edit-text'),
    })
    .set('help', { id: SINGLE_PLAN_HELP_ARTICLE_ID })
    .set('connect', { behavior: 'HIDE' });

  builder
    .gfpp('mobile')
    .set('mainAction1', {
      label: t('blocks.plan-name.gfpp.design-text'),
      onClick: (e) => {
        editorSDK.editor.openNativeComponentPanel('', 'settings', {
          componentRef: e.detail.componentRef,
          helpId: SINGLE_PLAN_HELP_ARTICLE_ID,
        });
      },
    })
    .set('settings', { behavior: 'HIDE' })
    .set('mainAction2', { behavior: 'HIDE' });
}

function configureDescription(params: {
  builder: ConnectedComponentsBuilder;
  flowAPI: EditorScriptFlowAPI;
  editorSDK: EditorSDK;
}) {
  const { builder, flowAPI, editorSDK } = params;
  const t = flowAPI.translations.t;
  builder.set({ displayName: t('blocks.label.plan-description') });
  builder.behavior().set({ dataEditOptions: 'TEXT_STYLE_ONLY' });
  builder
    .gfpp()
    .set('mainAction1', {
      label: t('blocks.plan-description.gfpp.design-text'),
      onClick: (e) => {
        editorSDK.editor.openNativeComponentPanel('', 'settings', {
          componentRef: e.detail.componentRef,
          helpId: SINGLE_PLAN_HELP_ARTICLE_ID,
        });
      },
    })
    .set('mainAction2', {
      onClick: (e) => openPlanForm({ editorSDK, flowAPI, componentRef: e.detail.componentRef }),
      label: t('blocks.plan-description.gfpp.edit-text'),
    })
    .set('help', { id: SINGLE_PLAN_HELP_ARTICLE_ID })
    .set('connect', { behavior: 'HIDE' });

  builder
    .gfpp('mobile')
    .set('mainAction1', {
      label: t('blocks.plan-description.gfpp.design-text'),
      onClick: (e) => {
        editorSDK.editor.openNativeComponentPanel('', 'settings', {
          componentRef: e.detail.componentRef,
          helpId: SINGLE_PLAN_HELP_ARTICLE_ID,
        });
      },
    })
    .set('settings', { behavior: 'HIDE' });
}

function configureDuration(params: {
  builder: ConnectedComponentsBuilder;
  flowAPI: EditorScriptFlowAPI;
  editorSDK: EditorSDK;
}) {
  const { builder, flowAPI, editorSDK } = params;
  const t = flowAPI.translations.t;
  builder.set({ displayName: t('blocks.label.plan-duration') });
  builder.behavior().set({ dataEditOptions: 'TEXT_STYLE_ONLY' });
  builder
    .gfpp()
    .set('mainAction1', {
      label: t('blocks.plan-duration.gfpp.design-text'),
      onClick: (e) => {
        editorSDK.editor.openNativeComponentPanel('', 'settings', {
          componentRef: e.detail.componentRef,
          helpId: SINGLE_PLAN_HELP_ARTICLE_ID,
        });
      },
    })
    .set('mainAction2', {
      onClick: (e) => openPlanForm({ editorSDK, flowAPI, componentRef: e.detail.componentRef }),
      label: t('blocks.plan-duration.gfpp.edit-text'),
    })
    .set('help', { id: SINGLE_PLAN_HELP_ARTICLE_ID })
    .set('connect', { behavior: 'HIDE' });

  builder
    .gfpp('mobile')
    .set('mainAction1', {
      label: t('blocks.plan-duration.gfpp.design-text'),
      onClick: (e) => {
        editorSDK.editor.openNativeComponentPanel('', 'settings', {
          componentRef: e.detail.componentRef,
          helpId: SINGLE_PLAN_HELP_ARTICLE_ID,
        });
      },
    })
    .set('settings', { behavior: 'HIDE' });
}

function configureButton(params: {
  builder: ConnectedComponentsBuilder;
  flowAPI: EditorScriptFlowAPI;
  editorSDK: EditorSDK;
}) {
  const { builder, flowAPI, editorSDK } = params;
  const t = flowAPI.translations.t;
  builder
    .panel<'StylableButton', 'settings'>('settings')
    .configureControls({ label: { hidden: false }, link: { hidden: true }, icon: { hidden: false } });

  builder
    .gfpp()
    .set('mainAction1', {
      label: t('blocks.button.gfpp.change-text'),
      onClick: (e) =>
        editorSDK.editor.openNativeComponentPanel('', 'settings', {
          componentRef: e.detail.componentRef,
          helpId: SINGLE_PLAN_HELP_ARTICLE_ID,
        }),
    })
    .set('help', { id: SINGLE_PLAN_HELP_ARTICLE_ID })
    .set('link', { behavior: 'HIDE' })
    .set('connect', { behavior: 'HIDE' });
}

function configureDivider(params: { builder: WidgetBuilder; editorSDK: EditorSDK; flowAPI: EditorScriptFlowAPI }) {
  const { builder, editorSDK, flowAPI } = params;
  const t = flowAPI.translations.t;
  builder.configureConnectedComponents(ElementRole.ContentDividerContainer, (dividerBuilder) => {
    dividerBuilder.set({ displayName: t('blocks.label.content-divider') });
    dividerBuilder.behavior().set({ closed: { selectable: false, hideFromHierarchy: true } });
  });

  builder.configureConnectedComponents(ElementRole.ContentDividerVertical, (dividerBuilder) => {
    dividerBuilder.set({ displayName: t('blocks.label.content-divider') });
    dividerBuilder
      .gfpp()
      .set('mainAction1', {
        label: t('blocks.content-divider.gfpp.design'),
        onClick: (e) =>
          editorSDK.editor.openNativeComponentPanel('', 'design', { componentRef: e.detail.componentRef }),
      })
      .set('help', { id: SINGLE_PLAN_HELP_ARTICLE_ID })
      .set('design', { behavior: 'HIDE' });
  });

  builder.configureConnectedComponents(ElementRole.ContentDividerHorizontal, (dividerBuilder) => {
    dividerBuilder.set({ displayName: t('blocks.label.content-divider') });
    dividerBuilder
      .gfpp()
      .set('mainAction1', {
        label: t('blocks.content-divider.gfpp.design'),
        onClick: (e) =>
          editorSDK.editor.openNativeComponentPanel('', 'design', { componentRef: e.detail.componentRef }),
      })
      .set('help', { id: SINGLE_PLAN_HELP_ARTICLE_ID })
      .set('design', { behavior: 'HIDE' });
  });
}

function configurePricing(params: {
  builder: ConnectedComponentsBuilder;
  flowAPI: EditorScriptFlowAPI;
  editorSDK: EditorSDK;
}) {
  const { builder, editorSDK, flowAPI } = params;
  const t = flowAPI.translations.t;

  builder.set({ displayName: t('blocks.label.pricing') });
  builder
    .gfpp()
    .set('mainAction1', {
      onClick: (e) => openPlanForm({ editorSDK, flowAPI, componentRef: e.detail.componentRef }),
      label: t('blocks.pricing.gfpp.edit-text'),
    })
    .set('help', { id: SINGLE_PLAN_HELP_ARTICLE_ID })
    .set('mainAction2', { behavior: 'HIDE' })
    .set('connect', { behavior: 'HIDE' });

  builder.gfpp('mobile').set('mainAction1', { behavior: 'HIDE' });

  builder.configureWidgetDesign((priceDesignBuilder) => {
    priceDesignBuilder.set({ title: t('blocks.pricing.design.title'), customHelpId: SINGLE_PLAN_HELP_ARTICLE_ID });
    priceDesignBuilder
      .tabs()
      .addTab((tabBuilder) => {
        tabBuilder.set({ label: t('blocks.pricing.design.price'), dependents: [ElementRole.Price] });
        tabBuilder.groups().set({
          roles: [ElementRole.Price],
        });
      })
      .addTab((tabBuilder) => {
        tabBuilder.set({ label: t('blocks.pricing.design.currency') });
        tabBuilder.groups().set({
          roles: [ElementRole.Currency],
        });
      })
      .addTab((tabBuilder) => {
        tabBuilder.set({ label: t('blocks.pricing.design.frequency') });
        tabBuilder.groups().set({
          roles: [ElementRole.Frequency],
        });
      });
  });
}

function configureBenefits(params: {
  builder: ConnectedComponentsBuilder;
  flowAPI: EditorScriptFlowAPI;
  editorSDK: EditorSDK;
}) {
  const { builder, editorSDK, flowAPI } = params;
  const t = flowAPI.translations.t;
  builder.set({ displayName: t('blocks.label.benefits') });
  builder
    .gfpp()
    .set('mainAction1', {
      label: t('blocks.benefits.gfpp.manage'),
      onClick: (e) => openPlanForm({ editorSDK, flowAPI, componentRef: e.detail.componentRef }),
    })
    .set('mainAction2', { behavior: 'HIDE' })
    .set('add', { behavior: 'HIDE' })
    .set('connect', { behavior: 'HIDE' })
    .set('help', { id: SINGLE_PLAN_HELP_ARTICLE_ID });

  builder.gfpp('mobile').set('mainAction1', { behavior: 'HIDE' }).set('mainAction2', { behavior: 'HIDE' });
}

function getPresets(state: WidgetState): WidgetDesignPresets {
  return PRESETS_BY_STATE[state].map(({ id, thumbnailWidth, thumbnailHeight, thumbnailSrc }) => ({
    id,
    layout: {
      width: thumbnailWidth,
      height: thumbnailHeight,
    },
    src: thumbnailSrc,
  }));
}

function openLayoutPanel(params: { editorSDK: EditorSDK; componentRef: ComponentRef; flowAPI: EditorScriptFlowAPI }) {
  const { editorSDK, componentRef, flowAPI } = params;
  flowAPI.fedops.interactionStarted(SinglePlanInteractions.OpenLayoutPanel);
  return editorSDK.editor.openComponentPanel(
    '',
    {
      title: flowAPI.translations.t('blocks.layouts-panel.title'),
      url: getPanelUrl('Plan', 'LayoutPanel'),
      height: 296,
      width: 288,
      componentRef,
      initialData: {
        componentRef,
      },
      helpId: SINGLE_PLAN_HELP_ARTICLE_ID,
    },
    (token) => {
      editorSDK.editor.showPanelPreloader(token);
    },
  );
}

async function openChangeRibbonTextPanel(params: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
}) {
  const { editorSDK, componentRef, flowAPI } = params;
  flowAPI.fedops.interactionStarted(SinglePlanInteractions.OpenRibbonTextPanel);
  return editorSDK.editor.openComponentPanel(
    '',
    {
      title: flowAPI.translations.t('blocks.ribbon.change-text.title'),
      url: getPanelUrl('Ribbon', 'ChangeText'),
      height: 100,
      width: 288,
      componentRef,
      initialData: {
        componentRef,
      },
      helpId: SINGLE_PLAN_HELP_ARTICLE_ID,
    },
    (token) => {
      editorSDK.editor.showPanelPreloader(token);
    },
  );
}
